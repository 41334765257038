import React from "react";

export const Article = ({ title, html, className }: Props) => <div className={className}>
    <h2>{title}</h2>
    <div className="markdown" dangerouslySetInnerHTML={{ __html: html }}>
    </div>
</div>

interface Props {
    title: string
    html: string
    className?: string
}