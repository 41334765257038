import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

const SEO = ({ title }: Props) => {
  const data = useStaticQuery<Data>(graphql`
      query SeoQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
        meta: markdownRemark(frontmatter: {contentId: {eq: "meta"}}) {
          id
          frontmatter {
            lang
            name    
            description
            locationName
            locationAddress
            ogImage {
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
        }
      lastRace:  allMarkdownRemark(
        filter:{frontmatter:{contentId: {eq: "race"}}},
        sort:{fields: [frontmatter___date], order: DESC},
        limit: 1) {
          edges {
              node {
                frontmatter {
                  date(formatString: "YYYY-MM-DD")
                }
              }
            }
      }
    }
  `);

  const image = data.site.siteMetadata.siteUrl + data.meta.frontmatter.ogImage.childImageSharp.fixed.src

  return (
    <Helmet
      htmlAttributes={{
        lang: data.meta.frontmatter.lang
      }}
      meta={[
        {
          name: `description`,
          content: data.meta.frontmatter.description
        },
        {
          property: `og:title`,
          content: data.meta.frontmatter.name
        },
        {
          property: `og:description`,
          content: data.meta.frontmatter.description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:url`,
          content: data.site.siteMetadata.siteUrl
        },
        {
          property: `og:image`,
          content: image
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: data.meta.frontmatter.name
        },
        {
          name: `twitter:description`,
          content: data.meta.frontmatter.description
        },
      ]
      }
      title={title}
      titleTemplate={`%s | ${data.meta.frontmatter.name}`}
    >
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org/",
          "@type": "Event",
          "name": "${data.meta.frontmatter.name}",
          "description": "${data.meta.frontmatter.description}",
          "startDate": "${data.lastRace.edges[0].node.frontmatter.date}",
          "endDate": "${data.lastRace.edges[0].node.frontmatter.date}",
          "image": "${image}",
          "location": {
            "@type": "Place",
            "name": "${data.meta.frontmatter.locationName}",
            "address": "${data.meta.frontmatter.locationAddress}"
          }
        }`}
      </script>
    </Helmet>
  );
}

interface Data {
  site: {
    siteMetadata: {
      siteUrl: string
    }
  },
  meta: {
    frontmatter: {
      lang: string
      name: string
      description: string
      locationName: string
      locationAddress: string
      ogImage: {
        childImageSharp: {
          fixed: {
            src: string
          }
        }
      }
    }
  },
  lastRace: {
    edges: {
      node: {
        frontmatter: {
          date: string
        }
      }
    }[]
  }
}

interface Props {
  title: string
};

export default SEO;
