import React from "react";
import { Link } from "gatsby";

export const CustomLink = ({ linkType, linkURL, children, className, activeStyle }: Props) => {
    if (linkType === `internal`) {
        return (
            <Link activeStyle={activeStyle} className={className} to={linkURL}>
                {children}
            </Link>
        );
    } else {
        return (
            <a className={className} href={linkURL} rel="noopener noreferrer" target="_blank">
                {children}
            </a>
        );
    }
};

interface Props {
    linkType: `internal` | `external`
    linkURL: string
    children: React.ReactNode
    className?: string
    activeStyle?: object
}