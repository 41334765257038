import React from "react";

import Header, { NavbarData } from "./header";
import { graphql } from "gatsby";

function Layout(props: Props) {

  const { frontmatter: { facebookPage }, html } = props.data.footerData
  return (
    <div className="flex flex-col min-h-screen text-gray-900">
      <Header {...props} />

      <main className="flex flex-col flex-1 w-full text-lg leading-relaxed">
        {props.children}
      </main>

      <footer className="bg-dark">
        <nav className="flex justify-between max-w-4xl mx-auto p-4 md:p-8 text-sm text-white">
          <div className="text-xl" dangerouslySetInnerHTML={{ __html: html }}></div>
          <div className="text-xl">
            <a className="text-white" href={facebookPage}>Facebook</a>
          </div>
        </nav>
      </footer>
    </div>
  );
}

interface Props {
  children: React.ReactNode
  data: LayoutData
};

export type LayoutData = FooterData & NavbarData

interface FooterData {
  footerData: {
    frontmatter: {
      facebookPage?: string
    },
    html: string
  }
}

export const query = graphql`
  fragment LayoutFragment on Query {
    footerData: markdownRemark(frontmatter: {contentId: {eq: "footer"}}) {
          id
          frontmatter {
            facebookPage
          }
          html
    }
    navbarData: markdownRemark(frontmatter: {contentId: {eq: "navbar"}}) {
          id
          frontmatter {
            registrationLink {
              shown              
              label
              linkType
              linkURL
            }
            resultsLink {
              shown              
              label
              linkType
              linkURL
            }
          }
        }
  }
`;

export default Layout;
