import React from "react";

export const PageSection = ({ className, children }: Props) => <section className={className}>
    <div className="max-w-4xl mx-auto px-4 py-8 md:p-8">
        {children}
    </div>
</section>

interface Props {
    className?: string
    children: React.ReactNode
}